import React, { useRef, useState, useEffect } from 'react';
import { CarouselPagination, ResponsiveImage, Box, Stack } from '@/components';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import Close from '@/assets/close.svg';
import ModalPortal from './ModalPortal';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import CarouselArrow from '@/assets/carouselArrow.svg';
import { modalWrapper, modalBackdrop } from '@/animations';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { selectIsRobot } from '@/store/index';

const MediaLightboxCarousel = ({ activeImage, onClose, slides, preloadSlides, open, hideAltAndDescription }) => {
  const carousel = useRef();
  const preloadElement = useRef();
  const [currentIndex, setCurrentIndex] = useState(activeImage);
  const [animateDelay, setAnimateDelay] = useState(false);
  const [startPreload, setStartPreload] = useState(false);
  const { t } = useTranslation('common');
  const isRobot = useSelector(selectIsRobot);

  useEffect(() => {
    let observerRefValue = preloadElement.current;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setStartPreload(true);
        observer.unobserve(observerRefValue);
      }
    });

    if (!isRobot) {
      if (observerRefValue?.getBoundingClientRect().top < window.screen.height) {
        setStartPreload(true);
      } else {
        observerRefValue && observer.observe(observerRefValue);
      }
    }
  }, [isRobot]);

  useEffect(() => {
    if (open) {
      setAnimateDelay(open);
    }
  }, [open]);

  useEffect(() => {
    function keydown(event) {
      console.log(event.key);
      switch (event.key) {
        case 'ArrowRight':
          move(1);
          break;
        case 'ArrowLeft':
          move(-1);
          break;
        case 'Escape':
          close();
          break;
      }
    }

    if (open) {
      window.addEventListener('keydown', keydown, { passive: true });
    } else {
      window.removeEventListener('keydown', keydown);
    }

    return () => window.removeEventListener('keydown', keydown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  const close = () => {
    setAnimateDelay(false);
    setTimeout(() => {
      onClose();
      carousel.current.destroy();
    }, 500);
  };

  return (
    <>
      <div ref={preloadElement}>
        {startPreload &&
          preloadSlides?.map((slide) => (
            <ResponsiveImage
              className="hidden"
              key={slide?.imageUrl}
              image={slide}
              widths={{ xs: 425, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 2560 }}
              dataNotLazy
            />
          ))}
      </div>
      {open ? (
        <ModalPortal>
          <Stack
            motion={modalWrapper}
            className="disable-scroll media-lightbox fixed top-0 z-50 flex h-screen w-screen flex-col items-center px-6 md:px-10 lg:px-6 lg:pb-[37px]"
          >
            <AnimatePresence>
              {animateDelay && (
                <>
                  <Box motion={modalBackdrop} className="absolute h-screen w-screen bg-black/90" onClick={close} />
                  <Box
                    motion={modalBackdrop}
                    className="absolute right-4 top-8 z-10 sm:right-8 sm:top-8 lg:right-10 lg:top-10"
                  >
                    <button className="btn icon dark size-12 bg-white/90 p-0" onClick={close}>
                      <Close role="presentation" aria-label="Close" />
                    </button>
                  </Box>
                  <Box
                    motion={modalBackdrop}
                    className="relative flex h-full w-full flex-col items-center justify-between"
                  >
                    <div className="flex w-full grow items-center justify-center lg:pt-6">
                      <Flicking
                        className="max-h-full w-full sm:h-max"
                        defaultIndex={activeImage}
                        ref={carousel}
                        panelsPerView={1}
                        align="center"
                        circular
                        onWillChange={(e) => setCurrentIndex(e.index)}
                        preventDefaultOnDrag
                      >
                        {slides?.map((slide, index) => {
                          if (!slide) return;

                          return (
                            <div key={index} className="flex w-full flex-col items-center justify-center px-1">
                              <div className="h-[calc(100vh-300px)] w-full sm:h-[calc(100vh-400px)] sm:max-h-max lg:h-[calc(100vh-188px)]">
                                <ResponsiveImage
                                  image={slide}
                                  widths={{ xs: 425, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 2560 }}
                                  imgProps={{ className: '!object-contain object-bottom' }}
                                />
                              </div>
                              {!hideAltAndDescription && (
                                <div className="mt-8 min-h-28 w-full font-gotham sm:min-h-24 lg:hidden">
                                  <p className="mb-4 line-clamp-2 text-base font-n-light tracking-paragraph text-white sm:w-[424px] lg:line-clamp-1">
                                    {slide?.headingTitle ? slide?.headingTitle : slide?.imageAlt}
                                  </p>

                                  <div
                                    className="line-clamp-2 text-sm font-s-light leading-6 text-white/75 sm:w-[424px]"
                                    dangerouslySetInnerHTML={{ __html: slide?.description || slide?.caption }}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </Flicking>
                    </div>
                    <div className="felx-col flex w-full items-start justify-between px-4 lg:mt-9 lg:flex-row">
                      <div className="relative hidden h-[80px] lg:block lg:w-1/3">
                        {slides?.map((slide, index) => (
                          <div
                            key={index}
                            className={classNames(
                              'absolute inset-0 w-11/12 transition-opacity',
                              currentIndex === index ? 'opacity-100' : 'opacity-0',
                            )}
                          >
                            {!hideAltAndDescription && (
                              <div className="h-fit w-full font-gotham">
                                <p className="mb-4 line-clamp-1 text-base font-n-light tracking-paragraph text-white">
                                  {slide?.headingTitle ? slide?.headingTitle : slide?.imageAlt}
                                </p>
                                <div
                                  className="line-clamp-2 text-sm font-s-light leading-6 text-white/75"
                                  dangerouslySetInnerHTML={{ __html: slide?.description || slide?.caption }}
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="flex h-full w-full justify-center pb-16 sm:pb-[108px] lg:w-1/3 lg:pb-0">
                        <CarouselPagination
                          animate={false}
                          carousel={carousel}
                          className="w-full sm:w-[316px] lg:w-[168px] lg:!items-start [&_button]:lg:!hidden"
                        />
                      </div>
                      <div className="hidden h-full w-1/3 justify-end gap-6 lg:flex">
                        <button className="btn icon" onClick={move} aria-label={t('general.previous')}>
                          <CarouselArrow className="rotate-0" />
                        </button>
                        <button className="btn icon" onClick={() => move(1)} aria-label={t('general.next')}>
                          <CarouselArrow className="rotate-180" />
                        </button>
                      </div>
                    </div>
                  </Box>
                </>
              )}
            </AnimatePresence>
          </Stack>
        </ModalPortal>
      ) : null}
    </>
  );
};

export default MediaLightboxCarousel;
